<template>
    <div>
        <!-- DISPLAY ONLY IF SLIDE BAR STYLE TYPE SELECTED -->
        <b-row v-show="prop_siteSettingArray.styleType == '7'">
            <!-- Headline1 -->
            <b-col md="12">
                <b-form-group label="Headline1" label-for="slideBarSay1">
                    <b-form-input type="text" name="slideBarSay1" v-model="prop_siteSettingArray.slideBarSay1" placeholder="Check out our latest sale" />
                </b-form-group>
            </b-col>

            <!-- Headline2 -->
            <b-col md="12">
                <b-form-group label="Headline2" label-for="slideBarSay2">
                    <b-form-input type="text" name="slideBarSay2" v-model="prop_siteSettingArray.slideBarSay2" placeholder="Check out our latest sale" />
                </b-form-group>
            </b-col>

            <!-- Headline3 -->
            <b-col md="12">
                <b-form-group label="Headline3" label-for="slideBarSay3">
                    <b-form-input type="text" name="slideBarSay3" v-model="prop_siteSettingArray.slideBarSay3" placeholder="Check out our latest sale" />
                </b-form-group>
            </b-col>

            <!-- Font family -->
            <b-col md="12">
                <b-form-group label="Font family" label-for="fontFamily">
                    <Select2 :options="textFontFamily" name="fontFamily" v-model="prop_siteSettingArray.fontFamily" />
                </b-form-group>
            </b-col>
        </b-row>

        <!-- DISPLAY ONLY IF SLIDE BAR STYLE TYPE NOT SELECTED -->
        <b-row v-show="prop_siteSettingArray.styleType != '7'">
            <!-- Headline -->
            <b-col md="12">
                <b-form-group label="Headline" label-for="captureBarSay">
                    <b-form-input type="text" name="captureBarSay" v-model="prop_siteSettingArray.captureBarSay" placeholder="Checkout our latest sale" />
                </b-form-group>
            </b-col>

            <!-- Caption -->
            <b-col md="12">
                <b-form-group label="Caption" label-for="captiontext">
                    <b-form-input type="text" name="captiontext" v-model="prop_siteSettingArray.captiontext" placeholder="Caption" />
                </b-form-group>
            </b-col>

            <!-- Name Placeholder -->
            <b-col md="12" v-if="prop_siteSettingArray.subGoal == 1 && (prop_siteSettingArray.collectFromVisitors == 2 || prop_siteSettingArray.collectFromVisitors == 3)">
                <b-form-group label="Name placeholder" label-for="namePlaceholder">
                    <b-form-input type="text" name="namePlaceholder" v-model="prop_siteSettingArray.namePlaceholder" placeholder="Your name" />
                </b-form-group>
            </b-col>

            <!-- Email Placeholder -->
            <b-col md="12" v-if="(prop_siteSettingArray.subGoal == 1 && (prop_siteSettingArray.collectFromVisitors == 1 || prop_siteSettingArray.collectFromVisitors == 2))">
                <b-form-group label="Email placeholder" label-for="emailPlaceholder" >
                    <b-form-input type="text" name="emailPlaceholder" v-model="prop_siteSettingArray.emailPlaceholder" placeholder="Your email"/>
                </b-form-group>
            </b-col>

            <!-- Phone Placeholder -->
            <b-col md="12" v-if="(prop_siteSettingArray.subGoal == 1 && prop_siteSettingArray.collectFromVisitors == 3)">
                <b-form-group label="Phone placeholder" label-for="phoneNumberPlaceholder">
                    <b-form-input type="text" name="phoneNumberPlaceholder" v-model="prop_siteSettingArray.phoneNumberPlaceholder" placeholder="Your phone number"/>
                </b-form-group>
            </b-col>

            <!-- START : BUTTON 2 -->
            <b-col md="12" v-if="!([5, 7].includes(prop_siteSettingArray.subGoal)) && prop_siteSettingArray.isModalButtonTwo && (isModalButtonTwoShowActive || prop_siteSettingArray.isModalButtonTwo != 0) && prop_siteSettingArray.styleType == 2">
                <b-form-group label="Button 2 text" label-for="modalButtonTwoSay">
                    <b-form-input type="text" name="modalButtonTwoSay" v-model="prop_siteSettingArray.modalButtonTwoSay" placeholder="Button Label" />
                </b-form-group>
            </b-col>
            <b-col md="12" v-if="!([5, 7].includes(prop_siteSettingArray.subGoal)) && prop_siteSettingArray.isModalButtonTwo && (isModalButtonTwoShowActive || prop_siteSettingArray.isModalButtonTwo != 0)&& prop_siteSettingArray.styleType == 2">
                <b-form-group label="Button 2 Redirect Link" label-for="modalButtonTwoRedirectLink">
                    <b-form-input type="text" name="modalButtonTwoRedirectLink"  v-model="prop_siteSettingArray.modalButtonTwoRedirectLink"  placeholder="Redirect Link" />
                </b-form-group>
            </b-col>
            <!-- END : BUTTON 2 -->

            <!-- Button Text -->
            <b-col md="12">
                <b-form-group label="Button text" label-for="buttonSay">
                    <b-form-input type="text" name="buttonSay" v-model="prop_siteSettingArray.buttonSay" placeholder="Button Label" />
                </b-form-group>
            </b-col>

            <!-- Font family -->
            <b-col md="12">
                <b-form-group label="Font family" label-for="fontFamily">
                    <Select2 :options="this.textFontFamily" name="fontFamily" v-model="prop_siteSettingArray.fontFamily" />
                </b-form-group>
            </b-col>

            <!-- Font family for button -->
            <b-col md="12">
                <b-form-group label="Font family for button" label-for="fontFamilyButton">
                    <Select2 :options="this.textFontFamilyForButton" name="fontFamilyButton" v-model="prop_siteSettingArray.fontFamilyButton" />
                </b-form-group>
            </b-col>

            <!-- Font family for Input -->
            <b-col md="12">
                <b-form-group label="Font family for Input" label-for="fontFamilyInput">
                    <Select2 :options="this.textFontFamilyForInput" name="fontFamilyInput" v-model="prop_siteSettingArray.fontFamilyInput" />
                </b-form-group>
            </b-col>

            <!-- Side Button Text -->
            <b-col md="12" v-show="prop_siteSettingArray.styleType == '6'">
                <b-form-group label="Side Button text" label-for="sideButtonSay">
                    <b-form-input type="text" name="sideButtonSay" v-model="prop_siteSettingArray.sideButtonSay" placeholder="Side Button Label" />
                </b-form-group>
            </b-col>

            <!-- Font family for Side button -->
            <b-col md="12" v-show="prop_siteSettingArray.styleType == '6'">
                <b-form-group label="Font family for side button" label-for="fontFamilySideButton">
                    <Select2 :options="this.textFontFamilyForInput" name="fontFamilySideButton" v-model="prop_siteSettingArray.fontFamilySideButton" />
                </b-form-group>
            </b-col>
        </b-row>
    </div>
</template>

<script>
    import { BRow, BCol, BFormInput, BFormGroup, BInputGroup, BInputGroupAppend, BFormCheckbox } from 'bootstrap-vue'
    import captureOptions from "@/libs/capture-Options"

    export default {
        components: {
            BRow, BCol, BFormInput, BFormGroup, BInputGroup, BInputGroupAppend, BFormCheckbox
        },
        name: 'TextsSetting',
        props: {
            prop_siteSettingArray: {
                type: Object,
                default: () => { },
            },
            isModalButtonTwoShowActive: {
                type: Boolean,
                default: false,
            }
        },

        setup() {
            const { textFontFamily, textFontFamilyForButton, textFontFamilyForInput } = captureOptions();
            return {
                textFontFamily, textFontFamilyForButton, textFontFamilyForInput
            }
        },
        created() {
            /** SET DEFAULT FONT-FAMILY WHEN ADD NEW A CAPTURE */
            if (!_.isEmpty(this.prop_siteSettingArray.isModalButtonTwo) && this.prop_siteSettingArray.isModalButtonTwo == 1) {
                this.prop_siteSettingArray.isModalButtonTwo = true;
            }
            if (_.isEmpty(this.prop_siteSettingArray.fontFamily)) {
                this.prop_siteSettingArray.fontFamily = 'Open Sans';
            }
            if (_.isEmpty(this.prop_siteSettingArray.fontFamilyButton)) {
                this.prop_siteSettingArray.fontFamilyButton = 'Open Sans';
            }
            if (_.isEmpty(this.prop_siteSettingArray.fontFamilyInput)) {
                this.prop_siteSettingArray.fontFamilyInput = 'Open Sans';
            }
            if (_.isEmpty(this.prop_siteSettingArray.sideButtonSay)) {
                this.prop_siteSettingArray.sideButtonSay = 'Click here';
            }
            if (_.isEmpty(this.prop_siteSettingArray.fontFamilySideButton)) {
                this.prop_siteSettingArray.fontFamilySideButton = 'Open Sans';
            }
            if (_.isEmpty(this.prop_siteSettingArray.modalButtonTwoSay)) {
                this.prop_siteSettingArray.modalButtonTwoSay = 'Click here';
            }
        }
    }
</script>
<style type="scss">
    input[type='color'] {
        width: 5rem;
    }
</style>